<template>
    <section class="contact-in-page">
        <div class="container">
          <div class="main-title wow slideInUp" data-wow-duration="1.3s" data-wow-delay="0s">
            <h2>{{ $t('contact_us') }}</h2>
            <p>{{ $t('call_us') }}</p>
          </div>
          <div class="content">
            <div class="row">
              <div class="col-lg-4">
                <div class="contact-info wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".2s">
                  <div class="content">
                    <div class="details">
                      <div class="info-box">
                        <div class="icon"><img class="img-fluid" data-src="images/icons/map.png" alt=""></div>
                        <div class="info">
                          <h4>{{ $t('address') }}</h4>
                          <p>{{settingsData.address}}</p>
                        </div>
                      </div>
                      <div class="info-box">
                        <div class="icon"><img class="img-fluid" data-src="images/icons/message.png" alt=""></div>
                        <div class="info">
                          <h4>{{ $t('email') }}</h4>
                          <p><a :href="'mailto:'+settingsData.email">{{settingsData.email}}</a></p>
                          <!-- <p><a href="mailto:info@test.com">info@test.com</a></p> -->
                        </div>
                      </div>
                      <div class="info-box">
                        <div class="icon"><img class="img-fluid" data-src="images/icons/support.png" alt=""></div>
                        <div class="info">
                          <h4>{{ $t('phone') }}</h4>
                          <p><a href="tel:+966553255414" dir="ltr">{{settingsData.phone}}</a></p>
                          <!-- <p><a href="tel:+966553255414" dir="ltr">+966553475414</a></p> -->
                        </div>
                      </div>
                    </div>
                    <div class="links">
                      <a class="bi bi-facebook" :href="settingsData.facebook" target="_blank" rel="noreferrer"></a>
                      <a class="bi bi-twitter" :href="settingsData.twitter" target="_blank" rel="noreferrer"></a>
                      <a class="bi bi-instagram" :href="settingsData.instagram" target="_blank" rel="noreferrer"></a>
                      <!-- <a class="bi bi-linkedin" href="#" target="_blank" rel="noreferrer"></a> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                  <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
</template>

<script>
  import { mapState } from "vuex";
  import ContactForm from '@/components/ContactForm';
  export default {
    components: { 
      ContactForm
    },
    computed: {
		...mapState(["settingsData"]),
    
    },
  };
</script>
