<template>
 <!--to top-->
      <div id="toTop">
        <button> <i class="bi bi-chevron-double-up"></i></button>
      </div>
      <!--to top-->
      <!--footer part-->
      <footer v-if="settingsData">
        <div class="container">
          <div class="footer-content">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="footer-box">
                  <div class="text">
                    <router-link class="footer-logo" to="/"> 
                      <!-- <img class="img-fluid mb-4" src="/../images/logo-white.png" alt="Our Logo"> -->
                      <h4 class="footer-logo">{{ $t('website_name') }}</h4>
                    </router-link>
                    <p>{{settingsData.footer_description}}</p>
                  </div>
                  <div class="social-links">
                    <h4>{{ $t('follow_socials') }}</h4>
                    <div class="links">
                      <a class="bi bi-facebook" :href="settingsData.facebook" target="_blank" rel="noreferrer"></a>
                      <a class="bi bi-twitter" :href="settingsData.twitter" target="_blank" rel="noreferrer"></a>
                      <a class="bi bi-instagram" :href="settingsData.instagram" target="_blank" rel="noreferrer"></a>
                      <!-- <a class="bi bi-linkedin" href="#" target="_blank" rel="noreferrer"></a> -->
                      </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 offset-lg-2 col-md-6">
                <div class="footer-box">
                  <h3>{{ $t('useful_links') }}</h3>
                  <ul class="map-links">
                    <li><router-link to="/">{{ $t('home') }}</router-link></li>
                    <li><router-link to="/about">{{ $t('about') }}</router-link></li>
                    <li><router-link to="/services">{{ $t('services') }}</router-link></li>
                    <li><router-link to="/blogs">{{ $t('blogs') }}</router-link></li>
                    <li><router-link to="/contact">{{ $t('contact') }}</router-link></li>
                  </ul>
                </div>
              </div>
              <!-- <div class="col-lg-4 col-md-12">
                <div class="footer-box">
                  <h3>last services</h3>
                  <ul class="map-links">
                    <li><a href="#!">Daily Home Care`</a></li>
                    <li><a href="services.html">Lorem ipsum dolor sit amet consectetur</a></li>
                    <li><a href="services.html">Daily Home Care</a></li>
                    <li><a href="services.html">Lorem ipsum dolor sit amet consectetur</a></li>
                    <li><a href="services.html">Specialized Care</a></li>
                  </ul>
                </div>
              </div> -->
            </div>
          </div>
          <div class="copyright">
            <div class="copy-text">
              <p>{{settingsData.copyrights}} | <a href="https://marwan.tech/ar" target="_blank">{{ $t('marwan_tech') }}</a></p>
            </div>
          </div>
        </div>
      </footer>
      <!--footer part-->
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'Footer',
  computed: {
		...mapState(["settingsData"]),
    
    },
}
</script>