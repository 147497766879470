import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import '@/assets/sass/style.scss'
import VueLazyload from '@jambonn/vue-lazyload'
// import 'jquery';
// window.$ = window.jQuery = require('jquery');
const app = createApp(App).use(i18n);

app.config.globalProperties.$filters = {
    stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
  }

app.use(i18n).use(store).use(router).use(VueLazyload).mount('#app');
