import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
// import About from '../views/About.vue';
import Services from '../views/Services.vue';
import SingleServiceView from '../views/SingleServiceView.vue';
import Blogs from '../views/Blogs.vue';
import SingleBlogView from '../views/SingleBlogView.vue';
import Contact from '../views/Contact.vue';

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, "$1") || "ar";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/services/:id',
    name: 'SingleServiceView',
    component: SingleServiceView
},
  {
    path: '/blogs',
    name: 'Blogs',
    component: Blogs
  },
  {
    path: '/blogs/:id',
    name: 'SingleBlogView',
    component: SingleBlogView
},
  
]

const router = createRouter({
  history: createWebHistory(locale),
  routes,
  linkActiveClass: "active",
})

export default router
