<template>
  <!--services-->
      <section class="services-in-page">
        <div class="container">
          <div class="main-title"><span class="top-title">{{ $t('our_services') }}</span>
            <h2>{{ $t('motherhood_services') }}</h2>
            <!-- <p>Read the most popular medical news and articles in health care industries to catch up with the latest policies and trends.</p> -->
          </div>
          <div class="content">
            <div class="row" v-if="servicesData">
              <div v-for="service in servicesData.services" :key="service.id" class="col-lg-4 col-md-6 box">
                <div class="service-box wow zoomInDown" data-wow-duration="1.3s" data-wow-delay="0s">
                  <div class="image"><router-link :to="'/services/'+service.id"><img class="img-fluid" v-lazy="service.image" :alt="service.image_alt"></router-link></div>
                  <div class="details">
                    <h3 class="title"><router-link :to="'/services/'+service.id">{{ service.title }}</router-link></h3>
                    <p>{{ $filters.stripHtml(service.description) }}</p>
                    <div class="btns"><router-link class="btn details-btn" :to="'/services/'+service.id">{{ $t('more_details') }}<i class="bi bi-chevron-double-right"></i></router-link></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: 'Services',
  components: { 
    },
    computed: {
		    ...mapState(["servicesData"])
    },
    created() {
      this.$store.dispatch("getServicesData");
    },
}
</script>
