<template>
   <section class="single-service" v-if="singleServicesData">
        <div class="container">
          <div class="content">
            <div class="row">
              <div class="col-lg-8">
                <div class="service-box">
                  <div class="image"><img class="img-fluid" v-lazy="singleServicesData.service.image" :alt="singleServicesData.service.image_alt"></div>
                  <div class="details">
                    <div class="title">
                      <h3>{{singleServicesData.service.title}}</h3>
                    </div>
                    <div class="text">
                      <p v-html="singleServicesData.service.description"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="side-widget">
                  <div class="other-services">
                    <div class="title">
                      <h4>{{ $t('all_services') }}</h4>
                    </div>
                    <ul v-for="service in singleServicesData.all_services" :key="service.id"> 
                      <li> <router-link :to="'/services/'+service.id">{{ service.title }}</router-link></li>
                    </ul>
                  </div>
                  <div class="help">
                    <div class="title">
                      <h4>{{ $t('have_a_question') }}</h4>
                    </div>
                    <p>{{ $t('how_we_can_help_you') }}</p>
                    <router-link class="btn" to="contact">{{ $t('contact_us') }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: 'SingleServiceView',
  components: { 
    },
    computed: {
		    ...mapState(["singleServicesData"])
    },
    created() {
      this.$store.dispatch("getSingleServicesData", {id:this.$route.params.id});
    },
}
</script>
