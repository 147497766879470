<template>
   <section class="single-blog" v-if="singleBlogsData">
        <div class="container">
          <div class="content">
            <div class="row">
              <div class="col-lg-8">
                <div class="blog-box">
                  <div class="image"><img class="img-fluid" v-lazy="singleBlogsData.blog.image" :alt="singleBlogsData.blog.image_alt"></div>
                  <div class="details">
                    <div class="title">
                      <h3>{{singleBlogsData.blog.title}}</h3>
                    </div>
                    <div class="text">
                      <p v-html="singleBlogsData.blog.description"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="side-widget">
                  <div class="other-blogs">
                    <div class="title">
                      <h4>{{ $t('all_blogs') }}</h4>
                    </div>
                    <ul v-for="blog in singleBlogsData.all_blogs" :key="blog.id"> 
                      <li> <router-link :to="'/blogs/'+blog.id">{{ blog.title }}</router-link></li>
                    </ul>
                  </div>
                  <div class="help">
                    <div class="title">
                      <h4>{{ $t('have_a_question') }}</h4>
                    </div>
                    <p>{{ $t('how_we_can_help_you') }}</p>
                    <router-link class="btn" to="contact">{{ $t('contact_us') }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: 'SingleBlogView',
  components: { 
    },
    computed: {
		    ...mapState(["singleBlogsData"])
    },
    created() {
      this.$store.dispatch("getSingleBlogsData", {id:this.$route.params.id});
    },
}
</script>
