<template>
<!--header-->
      <header> 
        <!--navbar desktop-->
        <nav class="navbar main-nav navbar-expand-lg">
          <div class="container-fluid"><router-link class="navbar-brand" to="/">
            <!-- <img class="img-fluid" src="/../images/logo-white.png" alt=""> -->
            <h1 class="logo">الأمومة والطفولة</h1>
            </router-link>
            <div class="group">
              <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#side_menu" aria-controls="side_menu" aria-expanded="false" aria-label="Toggle navigation">
                <div class="hamburger-menu"><span></span><span></span><span></span></div>
              </button>
              <div class="lang">
                <div class="dropdown" v-if="this.$i18n.locale == 'en'">
                  <button class="dropdown-toggle" id="langDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="icon"><img src="/../images/icons/en.png" alt=""><span>eng</span></div>
                  </button>
                  <ul class="lang-content dropdown-menu" aria-labelledby="langDropdown">
                    <li> <a href="#" @click="changeLangToAr"> <img src="/../images/icons/ar.png" alt="">ar</a></li>
                  </ul>
                </div>

                <div class="dropdown" v-if="this.$i18n.locale == 'ar'">
                  <button class="dropdown-toggle" id="langDropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="icon"><img src="/../images/icons/ar.png" alt=""><span>ar</span></div>
                  </button>
                  <ul class="lang-content dropdown-menu" aria-labelledby="langDropdown">
                    <li> <a href="#" @click="changeLangToEn"> <img src="/../images/icons/en.png" alt="">eng</a></li>
                  </ul>
                </div>
                
              </div>
            </div>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav">
                <li class="nav-item"><router-link  class="nav-link" to="/">{{ $t('home') }}</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/about">{{ $t('about') }}</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/services">{{ $t('services') }}</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/blogs">{{ $t('blogs') }}</router-link></li>
                <li class="nav-item"><router-link class="nav-link" to="/contact">{{ $t('contact_us') }}</router-link></li>
              </ul>
            </div>
          </div>
        </nav>
        <!--navbar desktop-->
        <!--side menu in mobile only-->
        <div class="offcanvas offcanvas-start" id="side_menu" data-bs-scroll="true" tabindex="-1" aria-labelledby="side_menu_label">
          <div class="offcanvas-header">
            <button class="btn-close btn-close-dark" type="button" data-bs-dismiss="offcanvas" data-bs-target="#side_menu" aria-controls="side_menu" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="navbar">
              <div class="collapsed navbar-collapse" id="navbarNavSide">
                <ul class="navbar-nav">
                  <li class="nav-item"><router-link class="nav-link" aria-current="page" to="/">{{ $t('home') }}</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/about">{{ $t('about') }}</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/services">{{ $t('services') }}</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/blogs">{{ $t('blogs') }}</router-link></li>
                  <li class="nav-item"><router-link class="nav-link" to="/contact">{{ $t('contact_us') }}</router-link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <!--header-->
  <router-view/>
</template>

<script>
export default {
  name: 'Nav',
  data() {
		return { langs: ['ar', 'en'] }
	},
	methods: {
		changeLangToAr() {
			if (this.$i18n.locale == 'en') {
				window.location = window.location.href.replace(`/${this.$i18n.locale}/`, '/ar/')
			}
		},
		changeLangToEn() {
			if (this.$i18n.locale == 'ar') {
				window.location = window.location.href.replace(`/${this.$i18n.locale}/`, '/en/')
			}
		},
	},
	created() {
		if (this.$i18n.locale == "ar") {
			let htmlElement = document.querySelector("html");
			htmlElement.setAttribute("dir", "rtl");
			htmlElement.setAttribute("lang", "ar");
		} else {
			let htmlElement = document.querySelector("html");
			htmlElement.setAttribute("dir", "ltr");
			htmlElement.setAttribute("lang", "en");
		}
	},
}
</script>