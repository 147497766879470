<template>
  <Nav/>
  <Footer/>
</template>
<script>
import Nav from '@/components/Nav'
import Footer from '@/components/Footer'
export default {
  components: {
    Nav,
    Footer
  },
  created() {
      this.$store.dispatch("getSettingsData");
    },
}

</script>