<template>
    <div class="contact-form wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".4s" v-if="contactFormData">
            <div class="alert alert-dismissible fade show" :class="errors ? 'alert-danger':'alert-success'" v-show="sent" role="alert"><strong>{{ $t('message_sent_successfully') }}</strong>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
            <form novalidate enctype="multipart/form-data" @submit.prevent="handleContactForm">
                        <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group position-relative">
                            <label for="">{{ $t('name') }} *</label>
                            <input class="form-control" type="text" :placeholder="$t('name')"  name="name" v-model="contactFormData.name" required>
                            <div class="text-danger text-capitalize">{{ contactErrors.name }}</div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group position-relative">
                            <label for="">{{ $t('email') }} *</label>
                            <input class="form-control" type="email" :placeholder="$t('email')" name="email" v-model="contactFormData.email" required>
                            <div class="text-danger text-capitalize">{{ contactErrors.email }}</div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group position-relative">
                            <label for="">{{ $t('phone') }} *</label>
                            <input class="form-control" type="number" :placeholder="$t('phone')" name="phone" v-model="contactFormData.phone" required>
                            <div class="text-danger text-capitalize">{{ contactErrors.phone }}</div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                            <label for="">{{ $t('message') }} *</label>
                            <textarea class="form-control" rows="5" :placeholder="$t('message')" name="message" v-model="contactFormData.message"></textarea>
                            <div class="text-danger text-capitalize">{{ contactErrors.message }}</div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-12">
                            <p class="successfully">Message Sent Successfully </p>
                        </div> -->
                        </div>
                        <div class="contact-btn">
                        <div class="outer-btn">
                            <button class="btn" type="submit"><span>{{ $t('send') }}</span></button>
                        </div>
                        </div>
                        <vue-recaptcha
                            class="mt-5"
                            @verify="onVerify" 
                            @expired="onCaptchaExpired" 
                            ref="recaptcha"  
                            sitekey="6Leg7OMfAAAAAC2M5DK4Ai8EXyzCUjE7FKkzUjsU">
                        </vue-recaptcha>
                    </form>
    </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import { VueRecaptcha } from 'vue-recaptcha';
  export default {
    name: 'ContactForm',
    components: { 
      VueRecaptcha
       },
    data() {
		return {
			sent: null,
			sending: null,
			verifiedRecapcha: null,
			recapchaError: null,
      errors: null,
      hasClientError: false,
			contactFormData: {
				name: null,
				phone: null,
				email: null,
				message: null,
			},
			contactErrors: {
				name: null,
				email: null,
				phone: null,
				message: null,
			},
		};
	},
    methods: {
      ...mapActions(["storeFormData"]),
      handleContactForm(e) {
        const fields = ['name', 'email', 'phone', 'message'];
        this.restErrors();
        this.hasClientError = false;
        if ( !(/[+0-9-]+/i.test(this.contactFormData.phone)) ) {
          	this.contactErrors.phone = 'ادخل رقم هاتف';
            this.hasClientError = true;
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.contactFormData.email)) {
          this.contactErrors.email = 'صيغة غير صحيحة للبريد الإلكتروني';
          this.hasClientError = true;
        }
        for (let i=0; fields.length > i; i++){
          if (!this.contactFormData[fields[i]]) {
           this.contactErrors[fields[i]] = 'مطلوب';
           this.hasClientError = true;
          } else if (this.contactFormData[fields[i]].length > 100) {
           this.contactErrors[fields[i]] = 'الحد الأقصى للأحرف 100 حرف';
           this.hasClientError = true;
          }
        }
        if (this.hasClientError){
          return;
        }
			if (this.verifiedRecapcha == true) {
				this.sending = true;
				this.sent = null;
				this.recapchaError = null
        
				this.storeFormData(this.contactFormData).then(() => {
					if (!this.$store.state.contactErrorsApi) {
						e.target.reset();
						this.sent = true;
						this.sending = null;
						this.onCaptchaExpired();
            this.restErrors();
            this.contactFormData.name = null;
            this.contactFormData.email = null;
            this.contactFormData.phone = null;
            this.contactFormData.message = null;
					
					} else {
						this.sending = null;
						this.sent = null;
						let errors = this.$store.state.contactErrorsApi.data.data;
						this.contactErrors.name = errors.name;
						this.contactErrors.email = errors.email;
						this.contactErrors.phone = errors.phone;
						this.contactErrors.message = errors.message;
					}
				});
			} else {
				this.recapchaError = true;
				setTimeout(() => {
					this.recapchaError = null;
				}, 5000);
			}
		},
      onVerify: function (response) {
        this.verifiedRecapcha = true;
        this.recapchaCode = response;
		  },
		  onCaptchaExpired: function () {
			  this.$refs.recaptcha.reset();
		  },
      restErrors: function () {
        this.contactErrors.name = null;
				this.contactErrors.email = null;
				this.contactErrors.phone = null;
				this.contactErrors.message = null;
       
      }
    }
  };
</script>