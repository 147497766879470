<template>
      <!--PreLoader-->
      <!-- <div class="loader">
        <video width="auto" height="auto" muted autoplay loop>
          <source src="video/loading.webm" type="video/mp4">
          <source src="videos/video.ogg" type="video/ogg">Your browser does not support the video tag.
        </video>
      </div> -->
      <!--PreLoader-->
    
      <!--main slider-->
      <section class="main-slider" v-if="homeData">
           <Carousel :items-to-show="1" :wrap-around="true" :autoplay="5000">
                  <slide v-for="slide in homeData.slider" :key="slide.id">
                    <div class="slider-item"><img class="img-fluid" v-lazy="slide.image" :alt="slide.image_alt">
                      <div class="item-details">
                        <div class="container">
                          <div class="item-text">
                            <h1 class="title">{{ slide.title }}</h1>
                            <p>{{ slide.description }}</p>
                            <div class="item-btn">
                              <router-link class="btn" to="/about">{{ $t('get_start_now') }}</router-link>
                              <router-link class="btn diff" to="/services">{{ $t('browse_services') }}</router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </slide>
                <template #addons>
                    <Navigation />
                    <Pagination />
                </template>
            </Carousel>
      </section>
      <!--main slider-->
      <!--about us-->
      <section class="about" v-if="homeData">
        <div class="container">
          <div class="content">
            <div class="row">
              <div class="col-lg-6">
                <img class="img-fluid" src="/../images/bgs/contact.jpg" alt="">
              </div>
              <div class="col-lg-6">
                 <div class="main-title wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".3s"><span class="top-title">{{ $t('about_subtitle') }}</span>
                  <h2>{{homeData.about.title}}</h2>
                </div>
                <div class="about-text wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".4s">
                  <p>{{ $filters.stripHtml(homeData.about.description) }}</p>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      <!--about us-->
      <!--services-->
      <section class="services">
        <div class="container">
          <div class="main-title"><span class="top-title">{{ $t('our_services') }}</span>
            <h2>{{ $t('motherhood_services') }}</h2>
            <!-- <p>Read the most popular medical news and articles in health care industries to catch up with the latest policies and trends.</p> -->
          </div>
          <div class="content">
            <div class="row" v-if="homeData">
              <div v-for="service in homeData.services" :key="service.id" class="col-lg-3 col-md-6 box">
                <div class="service-box wow zoomInDown" data-wow-duration="1.3s" data-wow-delay="0s">
                  <div class="image"><router-link :to="'/services/'+service.id"> <img class="img-fluid" v-lazy="service.image" :alt="service.image_alt"></router-link></div>
                  <div class="details">
                    <h3 class="title"><router-link :to="'/services/'+service.id">{{ service.title }}</router-link></h3>
                    <p>{{ $filters.stripHtml(service.description) }}</p>
                    <div class="btns"><router-link class="btn details-btn" :to="'/services/'+service.id">{{ $t('more_details') }}<i class="bi bi-chevron-double-right"></i></router-link></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="services-btn">
              <p><router-link class="btn" to="/services">{{ $t('all_services') }}</router-link></p>
            </div>
          </div>
        </div>
      </section>
      <!--services-->
      <!--subscribe-->
      <section class="subscribe"  v-if="homeData">
        <div class="container-fluid">
          <div class="content">
            <div class="row">
              <div class="col-lg-7 p-0">
                <div class="subscribe-image"><img class="img-fluid" :src="homeData.cta.image" alt="Call To Action Image"></div>
              </div>
              <div class="col-lg-5 p-0">
                <div class="subscribe-form wow slideInUp" data-wow-duration="1.3s" data-wow-delay=".2s">
                  <div class="main-title wow slideInUp" data-wow-duration="1.3s" data-wow-delay="0s">
                    <h3 class="heading">{{homeData.cta.title}}</h3>
                  </div>
                  <div class="main-title wow slideInUp" data-wow-duration="1.3s" data-wow-delay="0s">
                    <p class="desc">{{$filters.stripHtml(homeData.cta.description)}}</p>
                  </div>
                   <div class="main-title wow slideInUp" data-wow-duration="1.3s" data-wow-delay="0s">
                    <a :href="'tel:'+settingsData.phone"><div class="cta-icon"><i class="bi bi-telephone-inbound"></i></div></a>
                  </div>
                 
                  <!-- <form class="needs-validation" action="" method="" novalidate>
                    <h3 class="sub-title">Subscribe to get newsletter</h3>
                    <div class="input-group">
                      <input class="form-control" type="text" placeholder="Enter your email" aria-label="Recipient's username" aria-describedby="subscribeBtn">
                      <button class="btn btn-outline-secondary" id="subscribeBtn" type="button"> <i class="bi bi-mailbox"></i></button>
                    </div>
                  </form> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--subscribe-->
      <!--contact us-->
      <section class="contact">
        <div class="container">
          <div class="content">
            <div class="row">
              <div class="col-lg-4 p-0">
                <div class="image"><img class="img-fluid" src="/../images/bgs/contact.jpg" alt=""></div>
              </div>
              <div class="col-lg-8 p-0">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--contact us-->
      <!--clients-->
      <section class="clients" v-if="homeData">
        <div class="container">
          <div class="content">
            <!-- <div class="clients-carousel owl-carousel owl-theme"> -->
              <Carousel  :items-to-show="3" :wrap-around="true">
                <slide v-for="client in homeData.clients" :key="client.id">
                   <img class="img-fluid" v-lazy="client.image" alt="client logo">
                </slide>
              </Carousel>
            <!-- </div> -->
          </div>
        </div>
      </section>
      <!--clients-->
      
      <!--blogs-->
      <section class="services">
        <div class="container">
          <div class="main-title"><span class="top-title">{{ $t('our_blogs') }}</span>
            <h2>{{ $t('motherhood_blogs') }}</h2>
            <!-- <p>Read the most popular medical news and articles in health care industries to catch up with the latest policies and trends.</p> -->
          </div>
          <div class="content">
            <div class="row" v-if="homeData">
              <div v-for="blog in homeData.blogs" :key="blog.id" class="col-lg-3 col-md-6 box">
                <div class="service-box wow zoomInDown" data-wow-duration="1.3s" data-wow-delay="0s">
                  <div class="image"><router-link :to="'/blogs/'+blog.id"> <img class="img-fluid" v-lazy="blog.image" :alt="blog.image_alt"></router-link></div>
                  <div class="details">
                    <h3 class="title"><router-link :to="'/blogs/'+blog.id">{{ blog.title }}</router-link></h3>
                    <p>{{ $filters.stripHtml(blog.description) }}</p>
                    <div class="btns"><router-link class="btn details-btn" :to="'/blogs/'+blog.id">{{ $t('more_details') }}<i class="bi bi-chevron-double-right"></i></router-link></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="services-btn">
              <p><router-link class="btn" to="/blogs">{{ $t('all_blogs') }}</router-link></p>
            </div>
          </div>
        </div>
      </section>
      <!--blogs-->
      <!--theme colors-->
      <!--theme colors-->
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { mapState } from "vuex";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import ContactForm from '@/components/ContactForm';

export default {
  name: 'Home',
  components: { 
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ContactForm,
    }, 
    // methods: {
    //   stripHtml : function (html)
    //   {
    //   let tmp = document.createElement("DIV");
    //   tmp.innerHTML = html;
    //   return tmp.textContent || tmp.innerText || "";
    //   }
    // },
    computed: {
		...mapState(["homeData", "settingsData"]),
    
    },
    created() {
      this.$store.dispatch("getHomeData");
    },
}

</script>


<style>
.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color:  var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  /* padding: 10px; */
  display: block;
}

.carousel__prev,
.carousel__next {
  background-color:#6f42c1;
  box-sizing: content-box;
  border: 5px solid white;
}
.carousel__next {
  right: 25px;
}
.carousel__prev {
  left: 25px;
}
.carousel__pagination-button {
  background-color:#99afca;
}
.carousel__pagination-button--active{
  background-color:#6f42c1;
}
</style>
