import axios from 'axios';
import { createStore } from 'vuex';

// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';
axios.defaults.baseURL = 'https://demoyoursite.xyz/ramy/motherhood-backend/api';

export default createStore({
	state: {
		loading: true,
		homeData: null,
		aboutData: null,
		servicesData: null,
		singleServicesData: null,
		blogsData: null,
		contactErrorsApi: null,
		settingsData: null,
		locale: window.location.pathname.replace(/^\/([^/]+).*/i, '$1') || 'ar',
	},
	mutations: {
		setHomeData: (state, homeData) => (state.homeData = homeData),
		setAboutData: (state, aboutData) => (state.aboutData = aboutData),
		setServicesData: (state, servicesData) =>
			(state.servicesData = servicesData),
		setBlogsData: (state, blogsData) => (state.blogsData = blogsData),
		setSettingsData: (state, settingsData) =>
			(state.settingsData = settingsData),
		setSingleServicesData: (state, singleServicesData) =>
			(state.singleServicesData = singleServicesData),
		setSingleBlogsData: (state, singleBlogsData) =>
			(state.singleBlogsData = singleBlogsData),
	},
	actions: {
		async getHomeData({ commit }) {
			this.state.loading = true;
			await axios
				.get('home', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setHomeData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async getAboutData({ commit }) {
			this.state.loading = true;
			await axios
				.get('about', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setAboutData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async getServicesData({ commit }) {
			this.state.loading = true;
			await axios
				.get('services', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setServicesData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async getSingleServicesData({ commit }, payload) {
			this.state.loading = true;
			await axios
				.get('services/' + payload.id, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setSingleServicesData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async getBlogsData({ commit }) {
			this.state.loading = true;
			await axios
				.get('blogs', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setBlogsData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},

		async getSingleBlogsData({ commit }, payload) {
			this.state.loading = true;
			await axios
				.get('blogs/' + payload.id, {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					this.state.loading = false;
					commit('setSingleBlogsData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		async storeFormData({}, contactFormData) {
			await axios
				.post('contact', contactFormData, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
				.then(() => {
					this.state.contactErrorsApi = null;
				})
				.catch((error) => {
					this.state.contactErrorsApi = error.response;
				});
		},
		async getSettingsData({ commit }) {
			await axios
				.get('settings', {
					headers: {
						'Content-Type': 'application/json',
						'Accept-Language': this.state.locale,
					},
				})
				.then((response) => {
					commit('setSettingsData', response.data.data);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	modules: {},
});
